<template>
  <div>
    <KTCard ref="preview" v-bind:title="'Add new'">
      <template v-slot:title>
        <h3 class="card-label">
          <router-link :to="{ name: 'list-tank-charges' }">{{
            $t("MENU.ITEM.FUELS.TITLE")
          }}</router-link>
          <i class="mr-2"></i>
          <small class="">{{ $t("LABELS.ADD_NEW") }}</small>
        </h3>
      </template>
      <template v-slot:toolbar>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingTankCharge"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
      <template v-slot:body>
        <b-form>
          <TankChargesForm ref="TankChargesForm" :form="form"></TankChargesForm>

          <v-overlay :value="!firstLoader && isLoadingTankCharge">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
        </b-form>
      </template>
      <template v-slot:foot>
        <div
          v-show="_.size(errors) > 0"
          role="alert"
          class="alert alert-danger"
        >
          <div class="alert-text" v-for="(error, i) in errors" :key="i">
            {{ error[0] }}
          </div>
        </div>
      </template>
      <template v-slot:toolbarfoot>
        <div class="example-tools justify-content-center">
          <BackButton></BackButton>
          <SaveButtonDropdown
            :defaultAction="'continue'"
            :isLoading="isLoadingTankCharge"
            v-on:save="onSave($event)"
          ></SaveButtonDropdown>
        </div>
      </template>
    </KTCard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { SAVE_TANK_CHARGE } from "@/modules/tank-charges/store/tankcharges.module";

import SaveButtonDropdown from "@/view/content/forms/components/SaveButtonDropdown.vue";

import KTCard from "@/view/content/Card.vue";
import TankChargesForm from "./TankChargesFrom.vue";
import { mapGetters } from "vuex";

export default {
  name: "TankChargesAdd",
  components: {
    KTCard,
    SaveButtonDropdown,
    TankChargesForm,
  },
  data() {
    return {
      errors: {},
      form: {
        gasTankStationId: null,
        dateCharged: null,
        fuelableId: null,
        fuelableType: null,
        fuelableName: null,
        staffId: null,
        staffName: null,
        liters: null,
        mileage: null,
        is_mileage: null,
      },
    };
  },
  computed: {
    ...mapGetters(["isLoadingTankCharge"]),
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: this.$i18n.t("MENU.ITEM.FUELS.TITLE"),
        route: { name: "list-tank-charges" },
      },
      { title: this.$i18n.t("LABELS.ADD_NEW") },
    ]);
  },
  methods: {
    onSave(next) {
      let vm = this;
      this.$refs.TankChargesForm.$v.form.$touch();
      if (this.$refs.TankChargesForm.$v.form.$anyError) {
        vm.$notify({
          group: "notify",
          type: "error",
          title: "<i class='flaticon-close'></i> Warning",
          text: "Please fill all required fields correctly",
        });
        return;
      }
      this.$store
        .dispatch(SAVE_TANK_CHARGE, this.form)
        .then((data) => {
          vm.errors = data.data.errors;
          let itemEdit = data.data;
          vm.$notify({
            group: "notify",
            type: "success",
            title: "<i class='flaticon2-checkmark'></i> Success",
            text: data.message,
          });
          // let itemEdit = data.data;
          if (next == "continue") {
            //New
            this.$router.push({
              name: "edit-tank-charges",
              params: { id: itemEdit.id },
            });
          }
          if (next == "new") {
            //New
            this.$router.push({ name: "add-tank-charges" });
          }
          if (next == "exit") {
            this.$router.push({ name: "list-tank-charges" });
          }
        })
        .catch((response) => {
          vm.errors = response.data.errors;
          vm.$notify({
            group: "notify",
            type: "error",
            title: this.$t("ALERTS.ERROR"),
            text: response.data.message,
          });
        });
    },
  },
};
</script>
