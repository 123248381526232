<template>
  <b-form>
    <FormBuilder
      v-if="!firstLoader"
      :form="form"
      :schemaJson="schemaJson"
      :v="$v"
    ></FormBuilder>
  </b-form>
</template>

<script>
import { FETCH_MACHINE_AND_TANK_OPTIONS } from "@/modules/tank-charges/store/tankcharges.module";
import { FETCH_TANKS } from "@/modules/tanks/store/tanks.module";
import { FETCH_ALL_STAFF } from "@/modules/staff/store/staff.module";

import FormBuilder from "@/view/content/forms/FormBuilder.vue";

import { validationMixin } from "vuelidate";
import { formBuilderMixin } from "@/core/mixins/formBuilderMixin.js";

import { mapGetters, mapState } from "vuex";

export default {
  mixins: [validationMixin, formBuilderMixin],
  props: {
    form: Object,
  },
  data() {
    return {
      errors: {},
      firstLoader: true,
      tanks: [],
      vehicles: [],
      staffList: [],
      tempStaffList: null,
    };
  },
  components: {
    FormBuilder,
  },
  validations() {
    let tmpValidationObject = this.generateFormValidationsOutOfSchemaJson(
      this.schemaJson
    );

    return tmpValidationObject;
  },
  computed: {
    ...mapGetters(["isLoadingTankCharge"]),
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    schemaJson() {
      return {
        fields: [
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-from-group",
            id: "item-unit-from-input",
            feedback_id: "unit-live-feedback",
            colSm: "4",
            divider: false,
            clearable: false,
            disabled: this.form.id !== undefined,
            i18n: {
              label: "MENU.ITEM.TANKS.CHARGED_FROM",
              placeholder: "MENU.ITEM.TANKS.CHARGED_FROM",
            },
            options: this.tanks,
            validations: {
              required: true,
            },
            model: "gasTankStationId",
          },
          {
            type: "datepicker",
            inputType: "text",
            id: "date-charged-input",
            groupId: "date-charged-group",
            required: "required",
            colSm: "4",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "MENU.ITEM.FUELS.DATE_CHARGED",
              placeholder: "MENU.ITEM.FUELS.DATE_CHARGED",
              validation: "FORM.VALID_NAME",
            },
            validations: {
              required: true,
            },
            translatable: false,
            model: "dateCharged",
          },
          {
            type: "select",
            inputType: "select",
            groupId: "item-unit-group",
            id: "item-unit-input",
            feedback_id: "unit-live-feedback",
            colSm: "4",
            divider: false,
            clearable: false,
            disabled: this.form.id !== undefined,
            i18n: {
              label: "MENU.ITEM.TANKS.VEHICLE_GAS_STATION",
              placeholder: "MENU.ITEM.TANKS.VEHICLE_GAS_STATION",
            },
            options: this.vehicles,
            validations: {
              required: true,
            },
            model: "fuelable",
          },
          {
            type: "select",
            inputType: "select",
            groupId: "staff-name-group",
            id: "staff-name-type-input",
            feedback_id: "staff-name-live-feedback",
            colSm: "4",
            divider: false,
            clearable: false,
            i18n: {
              label: "MENU.ITEM.STAFF.STAFF",
              placeholder: "MENU.ITEM.STAFF.STAFF",
            },
            options: this.selectStaffList,
            validations: {},
            model: "staffId",
          },
          {
            type: "input",
            inputType: "text",
            id: "liters-input",
            groupId: "liters-group",
            required: "required",
            colSm: "4",
            feedback_id: "provider-live-feedback",
            divider: false,
            i18n: {
              label: "MENU.ITEM.FUELS.LITERS",
              placeholder: "MENU.ITEM.FUELS.LITERS",
              validation: "FORM.VALID_NAME",
            },
            validations: {
              required: true,
            },
            translatable: false,
            model: "liters",
          },
          {
            type: "input",
            inputType: "number",
            id: "mileages-input",
            groupId: "mileages-group",
            required: "required",
            colSm: "4",
            divider: false,
            i18n: {
              label: "MENU.ITEM.FUELS.MILEAGES",
              placeholder: "MENU.ITEM.FUELS.MILEAGES",
              validation: "FORM.VALID_NAME",
            },
            validations: {},
            translatable: false,
            model: "mileage",
          },
        ],
      };
    },
    selectStaffList() {
      return this.tempStaffList || this.staffList;
    },
  },
  watch: {
    "form.fuelable": {
      immediate: true,
      handler(value) {
        this.form.fuelableId = value.id;
        this.form.fuelableType = value.type;
        this.form.fuelableName = value.name;
        this.form.isMileage = value.isMileage;
        if (
          value.type === "OwnMachineExpense" ||
          value.type === "StaffMachineExpense"
        ) {
          this.tempStaffList = null;
          this.form.staffId = value.lastUsedStaffId || null;
        } else if (value.type === "RentedMachineExpense") {
          this.tempStaffList = [{ id: -1, name: value.staffName }];
          this.form.staffId = -1;
        } else if (value.type === "TankExpense") {
          this.tempStaffList = [];
          this.form.staffId = null;
        }
      },
    },
    "form.staffId": function(value) {
      if (value !== null) {
        const selectedStaff = this.selectStaffList.find((s) => s.id === value);
        if (selectedStaff) {
          this.form.staffName = selectedStaff.name;
        }
      } else {
        this.form.staffName = null;
      }
    },
  },
  mounted() {
    let vm = this;
    this.$store.dispatch(FETCH_TANKS).then((data) => {
      this.tanks = data.data.items;
    });

    this.$store.dispatch(FETCH_MACHINE_AND_TANK_OPTIONS).then((data) => {
      this.vehicles = data.data.map((v) => {
        return {
          id: {
            id: v.id,
            type: v.type,
            name: v.name,
            staffName: v.staffName,
            lastUsedStaffId: v.lastUsedStaffId,
            isMileage: v.isMileage,
            noMileage: v.noMileage,
          },
          name: v.name,
        };
      });
    });

    this.$store.dispatch(FETCH_ALL_STAFF, "?active=1").then((data) => {
      let items = data.data.items;

      this.staffList = items || [];
    });

    vm.generateFormOutOfSchemaJson(this.schemaJson);

    vm.$nextTick(function() {
      vm.firstLoader = false;
    });
  },
};
</script>
